import { useEffect } from 'react';
import './../App.css';
import urlsData from './../data/service/urls.json'

function Footer(){
    const urls = urlsData.data;

    useEffect(() => {
        return () => {
        };
    }, [])

    return(
        <>
            <div className='footer'>
                <div className='footer__con'>
                    <div className='footer__logo'>
                        <img src='/images/logo_black.png' decoding="async"/>
                    </div> 
                    <hr/>
                    <div className='footer__txt-con'>
                        <div className='footer__txt01'>
                            에이치로드 엔터테인먼트 | 사업자등록번호 : 825-19-01910
                        <br/>
                            주소 : 서울시 성동구 연무장길 17
                        <br/>
                            대표자 : 박효명 | 상담문의: 홈페이지 참조
                        <br/>
                        </div>
                        <div className='footer__txt02'>
                            H Road Entertainment. All Rights Reserved.
                        </div>
                    </div>
                    <ul className='footer__icon-con'>
                        <a href={urls.youtube}><img src='/icons/sns_youtube.png' /></a>
                        <a href={urls.instagram}><img src='/icons/sns_instagram.png' /></a>
                    </ul>
                    <div className='footer__law-con'>
                        <a href='/license'>오픈소스 라이선스</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;