import { useCallback, useEffect, useRef, useState } from 'react';
import './../../../App.css';
import { useNavigate, useParams } from 'react-router-dom';
// react-slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';

import { ServicesBySlick } from '../../Intro';



export default function Introduction(){
    const {id} = useParams();
    const [clickedData, setClickedData] = useState(null);

    // Click category
    // const [isClicked, setIsClicked] = useState(parseInt(id) || 1);

    // Scroll down to specific section
    const [scrollBlock, setScrollBlock] = useState('center');    
    useEffect(() => {
        const timer = setTimeout(()=>{setScrollBlock('start')}, 100) // Without this code, scroll stops at strange point
        const element = document.getElementById(id);
        element?.scrollIntoView({ behavior: "smooth", block: scrollBlock, inline: "nearest" });
    }, [scrollBlock])

    const navigate = useNavigate();

    // for mobile
    const displayMo = matchMedia("screen and (max-width: 1023px)"); // js 미디어쿼리

    return(
        <>
            <div className='company'>
                <div className='service__btn-con'>
                    <div className='service__btn-items'>
                        <ul>
                            {['인사말', '주요연혁', '서비스소개'].map((a,i) => {
                                return(
                                    <li
                                    className={`${id == (i+1) ? 'service__btn-after' : ''}`}
                                    >
                                        <a href={`/company/introduction/${i+1}`}>{a}</a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>

                <div className='section-one company-ceo'>
                    <div className='section-one__con'>
                        <div className='company-ceo__title'>
                            인사말
                        </div>
                        <div className='company-ceo__img-wrap'>
                            <img src='/images/ceo.png' decoding="async"/>
                            <span>
                                <img src='/images/logo_white.png' />
                            </span>
                        </div>
                        <div className='company-ceo__txt'>
                            에이치로드는 창의적이고 감동적인 공연을 기획하고 진행하는 데 있어 최상의 전문가들로 구성된 팀을 자랑합니다. 우리는 다양한 장르의 공연을 통해 관객에게 잊지 못할 경험을 선사하고, 아티스트들과의 협업을 통해 최고의 무대를 만들어내고 있습니다. 우리 회사는 이벤트 기획, 프로모션, 현장 운영까지 완벽하게 지원하여 성공적인 공연을 보장합니다.<br/><br/>

                            저희는 모든 공연이 하나의 예술 작품이라고 생각합니다. 따라서 공연 기획의 초기 단계부터 마무리까지 세심한 주의를 기울여 작업하며, 고객의 요구와 기대를 최우선으로 고려합니다. 저희의 목표는 단순히 공연을 개최하는 것을 넘어, 모든 관객이 감동을 느끼고 기억에 남을 만한 순간을 만들어 드리는 것입니다.<br/><br/>

                            또한, 저희는 공연 기획뿐만 아니라, 각종 행사와 이벤트 기획, 물품 대여 업무 등 다양한 서비스를 제공하고 있습니다. 다양한 규모와 성격의 행사를 성공적으로 진행한 경험을 바탕으로, 고객 맞춤형 서비스를 제공합니다. 저희 팀은 각 프로젝트마다 철저한 계획과 준비로 고객의 만족을 이끌어내며, 항상 최고의 결과를 목표로 합니다.
                        </div>
                    </div>
                </div>
            </div>


            <div className='company-history__con' id='2'>
                <div className='company-history__txt'>
                    <div className='company-history__txt-con'>
                        <div className='company-history__txt-sub'>
                            Our company-History
                        </div>
                        <div className='company-history__txt-title'>
                            <span>에이치로드</span>가<br/>지금까지 걸어온 길
                        </div>
                    </div>
                </div>

                <div className='company-history__list-con'>
                    <li>&nbsp;</li>
                    <ul>
                        <li>2024</li>
                        <ul>
                            <li>괴산 빨간맛 페스티벌 치어리딩 대회 기획 운영</li>
                            <li>창원 현대 위아 준공식 기획 운영</li>
                            <li>천안 보훈 문화제 행사 전문 MC</li>
                            <li>강남구 보건소 전문강사 위촉</li>
                            <li>대학 축제 전문 MC</li>
                            <li>기업 워크샵 기획 운영 및 전문 MC</li>
                            <li>천안 환서초 체육대회 기획 운영 (14일)</li>
                        </ul>
                    </ul>
                    <ul>
                        <li>2023</li>
                        <ul>
                            <li>성동구 청년 축제 전문 MC</li>
                            <li>광진구 청소넌 문화 축제 전문 MC</li>
                            <li>부여 백마강 달밤 야시장 기획 운영</li>
                            <li>부여 백마강 달밤 야시장 전문 MC</li>
                            <li>돌봄학교 문화공연 수업 기획 운영<br/>(서울, 경기, 충청, 전라, 경상)</li>
                            <li>군부대 찾아가는 문화 축제 기획 운영 및 전문 MC<br/>(사천 함안 거제 하동)</li>
                            <li>서울시 청소년 대상 보건 및 진로 교육 100회 이상 진행 및 최우수상</li>
                            <li>다수의 대학 M.T 행사 기획 운영 및 전문 MC</li>
                        </ul>
                    </ul>
                    <ul>
                        <li>2022</li>
                        <ul>
                            <li>부여 백제 문화제 길거리 버스킹 기획 운영</li>
                            <li>이천 세계 장애인의 날 문화 교류 기획 운영</li>
                            <li>수도권 중심 초등학교 문화공연 수업 기획 운영</li>
                            <li>행안부 주관 환경 캠페인 영상 기획 제작</li>
                            <li>서울 용산구 보건소 전문강사 위촉</li>
                            <li>다수의 중학교, 고등학교 축제 전문 MC</li>
                            <li>대한민국 100인의 뷰티 명장 시상식 전문 MC</li>
                        </ul>
                    </ul>
                    <ul>
                        <li>2021</li>
                        <ul>
                            <li>세종시 청소년 댄싱 페스타 전문 MC</li>
                            <li>광주 서구 자원봉사자 대회 기획 운영</li>
                            <li>다수의 특수학교 문화공연 수업 기획 운영</li>
                            <li>다수의 기업 송년회 기획 운영 및 전문 MC</li>
                            <li>체육대회 전문 MC</li>
                        </ul>
                    </ul>
                    <ul>
                        <li>2020</li>
                        <ul>
                            <li>강릉 반다비 스포츠 캠프 기획 운영</li>
                            <li>제 1회 트레일-런 전문 MC</li>
                            <li>청주 월드 삼보 챔피언십 개막식 기획 운영</li>
                            <li>전주시 자원봉사자 대회 기획 운영</li>
                            <li>외국인 대상 광복절 노래, 영상 대회 기획 운영 및 대상 수상</li>
                        </ul>
                    </ul>
                    <li>&nbsp;</li>
                </div>
            </div>

            <div className='section-one intro-menu' id='3'>
                <div className='section-one__con'>
                    <ServicesBySlick />
                </div>
            </div>
        </>
    )
}