import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './../App.css';
import urlsData from './../data/service/urls.json'


export default function Header(props){
    const thisPage = useLocation().pathname;
    const urls = urlsData.data;
    
    // scroll event
    const [scrollPosition, setScrollPosition] = useState(null);
    const [clickHamburger, setClickHamburger] = useState(null);

    // 스크롤 위치 인식
    const updateScroll = () => {
        setScrollPosition(window.scrollY || document.documentElement.scrollTop);
    }

    // 무한 스크롤 방지하기 위해서 100ms마다 스크롤 위치 체크
    useEffect(()=>{
        const timer = setTimeout(window.addEventListener('scroll', updateScroll), 100)       
        return () => {
            clearTimeout(timer);
        };
    });

    // 햄버거 버튼 클릭시 메뉴 보이기
    const showList = () => {
        setClickHamburger(!clickHamburger);
    };


    return (
        <>
            <header className={`header + ${scrollPosition < (thisPage == '/' ? 50 : 0) ? "" : "header-changed"} + header-unclick`}>
                <div className='header__con'>
                    {/* 로고 */}
                    <ul className='header__logo-con'>
                        <li className='header__logo'>
                            <a href='/'>
                                <img src={scrollPosition < (thisPage == '/' ? 50 : 0) ? '/images/logo-header_white.png' : '/images/logo-header_black.png'} decoding="async"/>
                            </a>
                        </li>
                        {/* <li className='header__logo'><a href='/'><img src='/img/logo_white.png' decoding="async"/></a></li> */}
                        <li className='header__hamburger'><a><img src={clickHamburger ? `/icons/btn_x.png` : `/icons/btn_hamburger.png`} onClick={showList}/></a></li>
                    </ul>
                    
                    {/* 메뉴 */}
                    <ul className='header__category-con'>
                        <li>
                            <a href='/'>
                                Home
                            </a>
                        </li>
                        <li className='header__dropdown'>
                            <a href='/company/introduction/1'>
                                Company
                            </a>
                            <ul>
                                <li><a href='/company/introduction/1'>
                                    Introduction
                                </a></li>
                                <li><a href='/company/introduction/2'>
                                    History
                                </a></li>
                                <li><a href='/company/introduction/3'>
                                    Services
                                </a></li>
                            </ul>
                        </li>
                        <li>
                            <a href='/service/1'>
                                Services
                            </a>
                        </li>
                        <li className='header__dropdown'>
                            <a href=''>
                                SNS
                            </a>
                            <ul>
                                <li><a href={urls.instagram}>
                                    Instagram
                                </a></li>
                                <li><a href={urls.youtube}>
                                    Youtube
                                </a></li>
                            </ul>
                        </li>
                        <li>
                            <a href={urls.kakao}>
                                Kakao
                            </a>
                        </li>
                        <li>
                            <a href={urls.call}>
                                Call
                            </a>
                        </li>
                    </ul>
                </div>
            </header>

            <div className={`header-mo ${clickHamburger ? 'left-0' : '' }`}>
                <ul className='header-mo__category-con'>
                    <li>
                        <a href='/'>
                            Home
                        </a>
                    </li>
                    <li className='header-mo__dropdown'>
                        <a>
                            Company
                        </a>
                        <ul>
                            <li><a href='/company/introduction/1'>
                                Introduction
                            </a></li>
                            <li><a href='/company/introduction/2'>
                                History
                            </a></li>
                            <li><a href='/company/introduction/3'>
                                Services
                            </a></li>
                        </ul>
                    </li>
                    <li>
                        <a href='/service/1'>
                            Services
                        </a>
                    </li>
                    <li className='header-mo__dropdown'>
                        <a>
                            SNS
                        </a>
                        <ul>
                            <li><a href={urls.instagram}>
                                Instagram
                            </a></li>
                            <li><a href={urls.youtube}>
                                Youtube
                            </a></li>
                        </ul>
                    </li>
                    <li>
                        <a href={urls.kakao}>
                            Kakao
                        </a>
                    </li>
                    <li>
                        <a href={urls.call}>
                            Call
                        </a>
                    </li>
                </ul>
            </div>

            <div className='bg-gray' style={{display: clickHamburger? 'block' : 'none'}} onClick={showList}></div>

            <div className={`header-block ${thisPage == '/' ? 'none' : ''}`}></div> {/* prevent for hiding other components */}
        </>
    )
}
