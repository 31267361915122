import { useCallback, useEffect, useRef, useState } from 'react';
import './../../../App.css';
import { useNavigate, useParams } from 'react-router-dom';
import myService from './../../../data/service/myService.json'



export default function Service(){
    const {id} = useParams();
    const [clickedData, setClickedData] = useState(null);

    // Click category
    // const [isClicked, setIsClicked] = useState(parseInt(id) || 1);

    const data = myService.data;

    // Scroll down to specific section
    const [scrollBlock, setScrollBlock] = useState('center');    
    useEffect(() => {
        const timer = setTimeout(()=>{setScrollBlock('start')}, 100) // Without this code, scroll stops at strange point
        const element = document.getElementById(id);
        element?.scrollIntoView({ behavior: "smooth", block: scrollBlock, inline: "nearest" });
    }, [scrollBlock])

    const navigate = useNavigate();

    // for mobile
    const displayMo = matchMedia("screen and (max-width: 1023px)"); // js 미디어쿼리

    return(
        <>
            <div className='service'>
                <div className='service__btn-con'>
                    <div className='service__btn-items'>
                        <ul>
                            {['퍼포먼스', 'MC진행', '에듀케이션'].map((a,i) => {
                                return(
                                    <li
                                    className={`${id == (i+1) ? 'service__btn-after' : ''}`}
                                    >
                                        <a href={`/service/${i+1}`}>{a}</a>
                                    </li>
                                )
                            })} 
                        </ul>
                        <ul>
                            {['공연기획 및 행사대행', '웨딩'].map((a,i) => {
                                return(
                                    <li
                                    className={`${id == (i+4) ? 'service__btn-after' : ''}`}
                                    >
                                        <a href={ a == '웨딩' ? 'https://hroad-wedding.com' : `/service/${i+4}`}>{a}</a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>

                <div className='section-one service-detail'>
                    <div className='section-one__con'>
                        <div className='service-detail__txt-con'>
                            <div className='service-detail__txt-left-con'>
                                <ul className='service-detail__txt-left-item'>
                                    <li>카테고리 설명</li>
                                    <div>
                                        {data.description[id-1].split("\n").map((line)=>(<div>{line}<br/><br/></div>))}
                                    </div>
                                </ul>
                                <ul className='service-detail__txt-left-item'>
                                    <li>세부 항목</li>
                                    <ul className='service-detail__txt-left-btn-con'>
                                        <ul>
                                            {data.detail_item[id-1].slice(0,3).map((a,i)=>{
                                                return(
                                                    <li>{a}</li>
                                                )
                                            })}
                                        </ul>
                                        <ul>
                                            {data.detail_item[id-1].slice(3,).map((a,i)=>{
                                                return(
                                                    <li>{a}</li>
                                                )
                                            })}
                                        </ul>
                                    </ul>
                                </ul>
                            </div>
                            <div className='service-detail__txt-right-con'>
                                <div className='service-detail__video-wrap'>
                                    <video autoPlay loop muted playsInline>
                                        <source src={`/videos/category0${id}/video.mp4`} type="video/mp4" />
                                    </video>
                                </div>  
                            </div>
                        </div>
                    </div>

                    <div className='section-one service-img'>
                        <div className='section-one__con'>
                                <div className='service-img__up-grid-con'>
                                    <div>
                                        <img src={`/videos/category0${id}/image01.jpg`} decoding="async"/>
                                    </div>
                                    <div>
                                        <img src={`/videos/category0${id}/image02.jpg`} decoding="async"/>
                                    </div>
                                    <div>
                                        <img src={`/videos/category0${id}/image03.jpg`} decoding="async"/>
                                    </div>
                                </div>
                                <div className='service-img__down-grid-con'>
                                    <div>
                                        <img src={`/videos/category0${id}/image04.jpg`} decoding="async"/>
                                    </div>
                                    <div>
                                        <img src={`/videos/category0${id}/image05.jpg`} decoding="async"/>
                                    </div>
                                </div>
                        </div>
                    </div>

                    

                </div>
            </div>
        </>
    )
}