import './App.css';
import { Route, Routes } from 'react-router-dom';
import { Intro, Footer, Header,
        Introduction,
        Service,
        License
        } from './routes';


function App() {
  return (
    
    <div className="App">
        <Header />
        <Routes>
            <Route path="/" element={<Intro />} />
            <Route path="/license" element={<License />} />

            <Route path="/company">
              <Route path="introduction/:id" element={<Introduction />}  />
            </Route>
            <Route path="/service/:id" element={<Service />} />
        </Routes>
        <Footer />
    </div>
  );
}

export default App;
