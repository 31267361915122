import { useCallback, useEffect, useRef, useState } from 'react';
import './../App.css';
import { useNavigate } from 'react-router-dom';
// react-slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
// scroll event
import { useScroll } from '../modules/useScroll/useScroll';

import myService from './../data/service/myService.json'
import urlsData from './../data/service/urls.json'




// for mobile
const displayMo = matchMedia("screen and (max-width: 1023px)"); // js 미디어쿼리

function Section({ children, classIs }) {
    return (
        <div className={`section-one ${classIs}`}>
            <div className='section-one__con'>
                {children}
            </div>
        </div>
    )
}

// Services By Slick
const linkToUrl = (i) => {
    window.location.href = (i === 4 ? 'https://hroad-wedding.com' : `/service/${i+1}`)
}

export function ServicesBySlick() {
    // Slick
    let slickRef = useRef();
    const previous = useCallback(() => slickRef.current.slickPrev(), []); // Cutomize arrows
    const next = useCallback(() => slickRef.current.slickNext(), []); // Cutomize arrows
    const settings = {
        // dots: true,
        // fade가 있으면 여러개의 slide 구현 안됨
        arrows: false,
        infinite: true,
        speed: 1000,
        slidesToShow: displayMo.matches ? 1 :3,
        slidesToScroll: 1,
        // slidesToScroll: displayMo.matches ? 1 :3,
        autoplay: true,
        autoplaySpeed: 8000,
        swipe:true,
    };

    return (
        <div className='intro-menu__slick-con'>
            {/* Customize arrows (it must be here in top position) */}
            <div className='intro-menu__slick-arrow-con'>
                <div onClick={previous}>
                    <img
                        src={"/icons/pre-arrow.png"}
                        alt={"pre-arrow"}
                    />
                </div>
                <div onClick={next}>
                    <img
                        src={"/icons/next-arrow.png"}
                        alt={"next-arrow"}
                    />
                </div>
            </div>
            
            <Slider className='intro-menu__slick' 
            {...settings}
            ref={slickRef} 
            >
                {['PERFORMANCE', 'THE BEST MC', 'EDUCATION', 'PLANNING', 'WEDDING'].map((a, i) => {
                    return(
                        <li
                            key={`${a}-${i}`}
                            onClick={() => {linkToUrl(i)}}>
                            <div>
                                <img src={`/images/service0${i+1}.jpeg`} />
                                <ul>
                                    <li>
                                        {a}
                                    </li>
                                </ul>
                            </div>
                        </li>
                        )
                    })
                }
            </Slider>
        </div>
    )
}



export default function Intro(){
    const navigate = useNavigate();
    
    const data = myService.data;
    const urls = urlsData.data;

    const [category, setCategory] = useState(0);
    const [categoryDetail, setCategoryDetail] = useState(0);
    const clickMenuItem = (el) => {
        setCategory(el);
        setCategoryDetail(0);
    }

    return(
        <>
            <div className='intro-hero'>
                <div className='intro-hero__bg'>
                    <video autoPlay loop muted playsInline>
                        <source src="/videos/intro.mp4" type="video/mp4" />
                    </video>
                    <div className='intro-hero__bg-gray'></div>
                </div>

                <div className='intro-hero__logo-wrap'>
                    <img src='/images/logo_white02.png' />
                </div>

                <ul className='intro-hero__features-con'>
                    {
                        [
                            'The best Quality',
                            'Professional Artists',
                            'Diverse Programs',
                            'Customized Service'
                        ].map((a,i)=>{
                            return(
                                <li key={`${a}-${i}`}>
                                    <img src={`/icons/hero${i}.svg`} />
                                    <span>{a}</span>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>

            <div className='section-one intro-company'>
                <div className='section-one__con'>
                    <div className='intro-company__flex-con'>
                        <div className='intro-company__txt-con'>
                            <div className='intro-company__txt-title'>최고의 경험을<br/>선사하는 에이치로드</div>
                            <div className='intro-company__txt-sub'>
                                저희 에이치로드 엔터테인먼트는 창의적이고 감동적인 공연을 기획하는 전문 회사입니다. 
                                <br/><br/>
                                다양한 장르의 공연을 통해 관객에게 잊지 못할 경험을 선사하며, 아티스트와 협업하여 최고의 무대를 만듭니다.
                                <br/><br/>
                                이벤트 기획, 프로모션, 현장 운영까지 완벽하게 지원하여 성공적인 공연을 보장합니다. 믿을 수 있는 파트너로 함께 하세요.
                            </div>
                            <a href='/company/introduction/1'>
                                <div className='btn-more'>
                                    더 알아보기 +
                                </div>
                            </a>
                            
                        </div>
                        <div
                        className='intro-company__img-wrap01'
                        style={{marginTop:`${(-120 - (useScroll('.intro-company__img-wrap01', 0).stateY * 0.05))}px`}}
                        >
                            <img src='/images/intro_company03.jpg' />
                        </div>
                        <div
                        className='intro-company__img-wrap02'
                        style={{marginTop:`${(400 + (useScroll('.intro-company__img-wrap01', 0).stateY * 0.5))}px`}}
                        >
                            <img src='/images/intro_company02.jpg' />
                        </div>
                    </div>

                    <div className='intro-comapny__video-wrap'>
                        <video autoPlay loop muted playsInline decoding="async">
                            <source src="/videos/intro-company.mp4" type="video/mp4" decoding="async" />
                        </video>
                    </div>
                    
                </div>

                <Section classIs='intro-menu'>
                    <div className='intro-menu__txt-con'>
                        <div className='intro-menu__txt-left-con'>
                            <div className='intro-menu__txt-left-sub'>OUR SERVICE</div>
                            <div className='intro-menu__txt-left-title'>Choose the service<br/>you want</div>
                        </div>
                        <div className='intro-menu__txt-right-con'>
                            <ul className='intro-menu__txt-right-item'>
                                <li>Choose your category</li>
                                <ul className='intro-menu__txt-right-btn-con'>
                                    <ul>
                                        {data.category.slice(0,2).map((a,i)=>{
                                            return(
                                                <li
                                                    key={`${a}-${i}`}
                                                    className={category == i ?
                                                'intro-menu__selected': ''}
                                                    onClick={()=>{clickMenuItem(i)}}>{a}</li>
                                            )
                                        })}
                                    </ul>
                                    <ul>
                                        {data.category.slice(2,).map((a,i)=>{
                                            return(
                                                <li
                                                    key={`${a}-${i}`}
                                                    className={category == i+2 ?
                                                'intro-menu__selected': ''}
                                                    onClick={()=>{clickMenuItem(i+2)}}>{a}</li>
                                            )
                                        })}
                                    </ul>
                                </ul>
                            </ul>

                            <ul className='intro-menu__txt-right-item'>
                                <li>Choose a detail service</li>
                                <ul className='intro-menu__txt-right-btn-con'>
                                    <ul>
                                        {data.detail_item[category].slice(0,3).map((a,i)=>{
                                            return(
                                                // <li>{a}</li>
                                                <li
                                                    key={`${a}-${i}`}
                                                    className={categoryDetail == i ?
                                                'intro-menu__selected': ''}
                                                    onClick={()=>{setCategoryDetail(i)}}>{a}</li>
                                            )
                                        })}
                                    </ul>
                                    <ul>
                                        {data.detail_item[category].slice(3,).map((a,i)=>{
                                            return(
                                                // <li>{a}</li>
                                                <li
                                                    key={`${a}-${i}`}
                                                    className={categoryDetail == i+2 ?
                                                'intro-menu__selected': ''}
                                                    onClick={()=>{setCategoryDetail(i+2)}}>{a}</li>
                                            )
                                        })}
                                    </ul>
                                </ul>
                            </ul>

                            <ul className='intro-menu__txt-right-item'>
                                <li>Description</li>
                                <div>
                                    에이치로드 만이 진행할 수 있는 {data.category[category]} 부분의 서비스를 원하시나요?<br className='br-pc'/>
                                    그럼 아래의 확인하기 버튼을 눌러서 더 자세한 경력과<br/>
                                    영상을 확인해보세요
                                </div>
                            </ul>

                            <a onClick={()=>{linkToUrl(category)}}>
                                <div className='btn-more'>
                                    더 확인하기 +
                                </div>
                            </a>
                            
                        </div>
                    </div> 

                    <ServicesBySlick />
                </Section>

                <Section classIs='intro-contact'>
                    <div className='intro-contact__txt'>
                        <div className='intro-contact__txt-con'>
                            <div className='intro-contact__txt-title'>
                                에이치로드를 만나보세요
                            </div>
                            <div className='intro-contact__txt-sub'>
                                <li>&#10003;&nbsp;&nbsp;고객님께 필요한 맞춤 기획을 진행합니다</li>
                                <li>&#10003;&nbsp;&nbsp;다양한 MC, 가수 전문가와 함께하세요</li>
                                <li>&#10003;&nbsp;&nbsp;물품 대여부터 현장 지원까지 모두 가능합니다 </li>
                            </div>
                            <a href={urls.kakao}>
                                <div className='btn-more'>
                                    지금 문의하기
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='intro-contact__img-wrap'>
                        <img src='/images/logo_white02.png' decoding="async"/>
                    </div>
                </Section>
            </div>
        </>
    )
}